import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/models/app.state';
import { TaggingTeam } from 'src/app/shared/models/current-selections.model';
import { setCurrentTaggingTeamAction } from 'src/app/shared/store/actions/current-selections.actions';
import {
  $currentTaggingTeam,
  $isTeamTaggingChangeEnabled,
} from 'src/app/shared/store/selectors/current-selections.selectors';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'cmv-tagging-team-menu',
  templateUrl: './tagging-team-menu.component.html',
  styleUrls: ['./tagging-team-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgClass,
    MatIcon,
    MatIconButton,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    AsyncPipe,
    TranslateModule,
    MatTooltip,
  ],
})
export class TaggingTeamMenuComponent {
  @Input()
  inVideoPlayer = false;

  taggingTeamShown = false;
  taggingTeam = TaggingTeam;
  currentTaggingTeam$ = this.store.pipe(select($currentTaggingTeam));
  isTeamTaggingChangeEnabled$ = this.store.pipe(
    select($isTeamTaggingChangeEnabled),
  );

  constructor(private readonly store: Store<AppState>) {}

  selectTaggingTeam(team: TaggingTeam): void {
    this.store.dispatch(setCurrentTaggingTeamAction({ team }));
  }

  taggingTeamString(team: TaggingTeam): string {
    switch (team) {
      case TaggingTeam.BOTH:
        return 'A|B';
      case TaggingTeam.HOME:
        return 'A';
      case TaggingTeam.AWAY:
        return 'B';
    }
  }

  toggleOptions(): void {
    this.taggingTeamShown = !this.taggingTeamShown;
  }
}
