import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'cmv-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LoadingBarComponent {}
