import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { Annotation, Video } from 'src/api/models';
import { isOlderThan } from 'src/app/app.utils';
import { OffsetModel } from '../../models/offset.model';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { CurrentVideoModel } from '../../models/current-video.model';
import { ChangeSrcModel } from '../../models/change-src.model';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../models/app.state';
import { $isFakeFullscreenActive } from '../../store/selectors/video.selectors';
import { NgIf, NgClass, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-video-player-wrapper',
  templateUrl: './video-player-wrapper.component.html',
  styleUrls: ['./video-player-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgClass,
    VideoPlayerComponent,
    NgTemplateOutlet,
    MatIcon,
    AsyncPipe,
    TranslateModule,
  ],
})
export class VideoPlayerWrapperComponent {
  @ViewChild('videoPlayer')
  player: VideoPlayerComponent;

  @Input() offset: OffsetModel;
  @Input() videoUrl: string;
  @Input() autoplay: boolean;
  @Input() createTag: (tag: Partial<Annotation>) => void;

  @Input() loading: boolean = false;
  @Input() isMissingVideo: boolean = false;
  @Input() notInRange: boolean = false;
  @Input() recordingEnd: number;
  @Input() enableChangeVideoSource: boolean = true;
  @Input() currentVideo: CurrentVideoModel;
  @Input() videoSources: Video[];

  @Output() clipEnded = new EventEmitter<boolean>();
  @Output() videoCurrentTime = new EventEmitter<number>();
  @Output() changeSrc = new EventEmitter<ChangeSrcModel>();
  @Output() seeked = new EventEmitter<number>();
  @Output() playerReady = new EventEmitter<void>();

  constructor(private readonly store: Store<AppState>) {}

  readonly isFakeFullscreen$ = this.store.pipe(select($isFakeFullscreenActive));

  isOlderThanOneDay(date: number): boolean {
    return isOlderThan(date, 1, 'd');
  }

  setCurrentTime(time: number) {
    this.player.api.currentTime = time;
  }

  get videoMissingTitle() {
    if (this.notInRange) {
      return 'components.videoPlayer.noVideoTitle';
    }

    return this.isOlderThanOneDay(this.recordingEnd)
      ? 'components.videoPlayer.notFoundTitle'
      : 'components.videoPlayer.notFound';
  }

  get videoMissingSubTitle() {
    if (this.notInRange) {
      return 'components.videoPlayer.notInRange';
    }

    return this.isOlderThanOneDay(this.recordingEnd)
      ? 'components.videoPlayer.notFoundTitle'
      : 'components.videoPlayer.willBeUploaded';
  }
}
