import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Annotation, RecordingStatus } from '../../../api/models';
import { QueryParamNames, RoutePath } from '../../app.constants';
import { AppState } from '../../shared/models/app.state';
import { FilterSearchModelType } from '../../shared/models/filter-search-model-type.enum';
import { FilterSearchResultModel } from '../../shared/models/filter-search-result.model';
import {
  deleteAnnotationRequestAction,
  editAnnotationNameRequestAction,
} from '../../shared/store/actions/annotation.actions';
import {
  $filterSearchLoading,
  $filterSearchNoResult,
  $filterSearchResult,
} from '../../shared/store/selectors/filter-search.selectors';
import { $recording } from 'src/app/shared/store/selectors/recording.selectors';
import { take } from 'rxjs';
import { LiveTaggingModes } from 'src/app/shared/models/current-selections.model';
import { changeLiveTaggingModeAction } from 'src/app/shared/store/actions/current-selections.actions';
import { SearchComponent } from '../../shared/components/search/search.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatchWidgetComponent } from '../../shared/components/match-widget/match-widget.component';
import { VideoWidgetComponent } from '../../shared/components/video-widget/video-widget.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-filter-result',
  templateUrl: './filter-result.component.html',
  styleUrls: ['./filter-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SearchComponent,
    NgIf,
    NgFor,
    MatchWidgetComponent,
    VideoWidgetComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class FilterResultComponent {
  FilterSearchModelType = FilterSearchModelType;

  readonly filterResults$ = this.store.pipe(select($filterSearchResult));
  readonly filterResultsLoading$ = this.store.pipe(
    select($filterSearchLoading),
  );
  readonly noResults$ = this.store.pipe(select($filterSearchNoResult));

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
  ) {}

  resultsTrackBy(_: number, value: FilterSearchResultModel): string {
    return value.id;
  }

  editAnnotation(annotation: Annotation): void {
    this.store.dispatch(
      editAnnotationNameRequestAction({
        id: annotation.id,
        name: annotation.name,
        description: annotation.description,
      }),
    );
  }

  redirectToTrimming(id: string, recordingId: string): void {
    this.store.dispatch(
      changeLiveTaggingModeAction({ mode: LiveTaggingModes.STREAM }),
    );
    this.store
      .select($recording(recordingId))
      .pipe(take(1))
      .subscribe(recording => {
        const subroute =
          recording?.status === RecordingStatus.LIVE
            ? RoutePath.LiveMatch
            : RoutePath.PlayedMatch;

        this.router.navigate(
          [RoutePath.Platform, RoutePath.Recordings, subroute],
          {
            queryParams: {
              [QueryParamNames.Trimming]: true,
              [QueryParamNames.AnnotationId]: id,
              [QueryParamNames.RecordingId]: recordingId,
            },
          },
        );
      });
  }

  deleteTag(annotation: Annotation): void {
    this.store.dispatch(deleteAnnotationRequestAction({ annotation }));
  }

  redirectToAnnotation(id: string, recordingId: string): void {
    this.store.dispatch(
      changeLiveTaggingModeAction({ mode: LiveTaggingModes.STREAM }),
    );
    this.store
      .select($recording(recordingId))
      .pipe(take(1))
      .subscribe(recording => {
        const subroute =
          recording?.status === RecordingStatus.LIVE
            ? RoutePath.LiveMatch
            : RoutePath.PlayedMatch;

        this.router.navigate(
          [RoutePath.Platform, RoutePath.Recordings, subroute],
          {
            queryParams: {
              [QueryParamNames.AnnotationId]: id,
              [QueryParamNames.RecordingId]: recordingId,
            },
          },
        );
      });
  }
}
