<div class="video-control-panel--wrapper">
  <div class="video-control-panel">
    <div class="cmv-h4">
      @if (match) {
        {{ matchDate }}
        @if (matchTime) {
          <span class="match-time">{{ matchTime }}</span>
        }
      } @else {
        {{ title }}
      }
    </div>
    <div class="controls-wrapper">
      @if (match && isEditButtonVisible(recorders$ | async)) {
        <button
          [matTooltip]="'tooltips.editRecording' | translate"
          (click)="editRecording()"
          mat-icon-button
        >
          <mat-icon
            class="button-icon cmv-dim-gray-icon open-recording-Info"
            svgIcon="cmv-edit"
          ></mat-icon>
        </button>
      }

      @if (match && isShareButtonVisible(recorders$ | async)) {
        <button
          (click)="openShareModal()"
          [matTooltip]="'tooltips.shareRecording' | translate"
          mat-icon-button
        >
          <mat-icon
            class="button-icon cmv-dim-gray-icon open-recording-Info"
            svgIcon="cmv-share"
          ></mat-icon>
        </button>
      }

      <cmv-download-video-menu
        *ngIf="downloadEnabled"
        [downloadVideos]="downloadVideos"
        [liveVideos]="liveVideos"
      ></cmv-download-video-menu>

      @if (match && isDeleteButtonVisible(recorders$ | async)) {
        <button
          (click)="deleteRecording()"
          [matTooltip]="'tooltips.removeRecording' | translate"
          mat-icon-button
        >
          <mat-icon
            class="button-icon cmv-dim-gray-icon open-recording-Info"
            svgIcon="cmv-delete"
          ></mat-icon>
        </button>
      }

      <cmv-recording-info *ngIf="infoEnabled"></cmv-recording-info>
    </div>
  </div>
  <div class="description cmv-body" *ngIf="description">
    {{ description }}
  </div>
  <cmv-loading-bar *ngIf="isLoadingVisible"></cmv-loading-bar>
</div>
