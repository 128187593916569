import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  MatFormField,
  MatLabel,
  MatOption,
  MatSelect,
  MatSelectChange,
} from '@angular/material/select';
import { Store, select } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AppState } from 'src/app/shared/models/app.state';
import {
  setAnnotationFilterTagsAction,
  setAnnotationFilterTeamsAction,
} from 'src/app/shared/store/actions/current-selections.actions';
import { getFilterSearchTagsRequestAction } from 'src/app/shared/store/actions/filter-search.actions';
import {
  $filterTags,
  $filterTeams,
} from 'src/app/shared/store/selectors/current-selections.selectors';

@Component({
  selector: 'cmv-created-tags-filter',
  templateUrl: './created-tags-filter.component.html',
  styleUrls: ['./created-tags-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    AsyncPipe,
    TranslateModule,
  ],
})
export class CreatedTagsFilterComponent {
  readonly filterSearchTags$ = this.store.pipe(select($filterTags));
  readonly currentRecordingTeams$ = this.store.pipe(select($filterTeams));

  constructor(private readonly store: Store<AppState>) {
    this.store.dispatch(getFilterSearchTagsRequestAction());
  }

  tagSelectChanged(event: MatSelectChange) {
    const tags: string[] = event.value;
    this.store.dispatch(setAnnotationFilterTagsAction({ tags }));
  }

  teamSelectChanged(event: MatSelectChange) {
    const teams: string[] = event.value;
    this.store.dispatch(setAnnotationFilterTeamsAction({ teams }));
  }
}
