import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Recording, RecordingType } from 'src/api/models';
import { MatchWidgetTeamComponent } from '../match-widget/match-widget-team/match-widget-team.component';

@Component({
  selector: 'cmv-video-header-panel',
  templateUrl: './video-header-panel.component.html',
  styleUrls: ['./video-header-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatchWidgetTeamComponent],
})
export class VideoHeaderPanelComponent {
  @Input() match: Recording;

  RecordingType = RecordingType;
}
