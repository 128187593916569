<div class="header" [attr.selected-team]="currentTaggingTeam$ | async">
  <div
    class="club-logo first-logo"
    [class.hidden]="(firstTaggingTeamIcon$ | async) == null"
    [style.background-image]="'url(' + (firstTaggingTeamIcon$ | async) + ')'"
  ></div>
  <div class="cmv-h4 header-title">
    {{ 'components.taggingPanel.addTags' | translate }}
  </div>
  <div
    class="club-logo second-logo"
    [class.hidden]="(secondTaggingTeamIcon$ | async) == null"
    [style.background-image]="'url(' + (secondTaggingTeamIcon$ | async) + ')'"
  ></div>
  <cmv-tagging-team-menu></cmv-tagging-team-menu>
</div>
