import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  filter,
  first,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { CurrentVideoModel } from 'src/app/shared/models/current-video.model';
import { Annotation, Recording, Video } from '../../../../api/models';
import { QueryParamNames, RoutePath, TagTabList } from '../../../app.constants';
import { getFullMatchName, TAGS_ANIMATION } from '../../../app.utils';
import { AppState } from '../../../shared/models/app.state';
import {
  deleteAnnotationRequestAction,
  editAnnotationNameRequestAction,
  prepareAnnotationPostRequestAction,
  prepareCurrentAnnotationTrimmingRequestAction,
} from '../../../shared/store/actions/annotation.actions';
import {
  changeCurrentVideoAction,
  disableAnnotationFilterAction,
  disableMultiselectAction,
  toggleAnnotationFilterAction,
  toggleMultiselectAction,
} from '../../../shared/store/actions/current-selections.actions';
import {
  $canDownloadAnnotationList,
  $isAnnotationListRendering,
  $shareableList,
} from '../../../shared/store/selectors/annotations-lists.selectors';
import {
  $currentRecording,
  $currentRecordingDownloadVideos,
  $currentRecordingVideos,
  $currentRecordingLiveVideos,
  $currentSignedUrl,
  $currentVideo,
  $isAnyCurrentRecordingVideoUserRemuxing,
  $currentVideoLoading,
  $currentAnnotation,
  $currentAnnotationOffset,
  $currentAnnotationValue,
  $trimmingDisabled,
  $isMultiselectEnabled,
  $isAnnotationFilterEnabled,
  $filteredAnnotations,
  $showFilter,
} from '../../../shared/store/selectors/current-selections.selectors';
import { $videoTaggingViewLoading } from '../../../shared/store/selectors/loading-components.selectors';
import {
  $mBreakPoint,
  $xlBreakPoint,
} from '../../../shared/store/selectors/responsivity.selectors';
import { $isSharingEnabled } from '../../../shared/store/selectors/sharing.selectors';
import {
  $currentRecordingId,
  $isTrimming,
} from 'src/app/shared/store/selectors/route.selectors';
import { TrimmingInputModel } from 'src/app/shared/models/trimming-input.model';
import { OffsetModel } from 'src/app/shared/models/offset.model';
import { SEEK_TO } from 'src/app/shared/components/trimming-panel/trimming-panel.component';
import { NgIf, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { VideoPageLayoutComponent } from '../../../shared/components/video-page-layout/video-page-layout.component';
import { VideoPlayerWrapperComponent } from '../../../shared/components/video-player-wrapper/video-player-wrapper.component';
import { VideoControlPanelComponent } from '../../../shared/components/video-control-panel/video-control-panel.component';
import { TrimmingPanelComponent } from '../../../shared/components/trimming-panel/trimming-panel.component';
import { TaggingPanelComponent } from '../../../shared/components/tagging-panel/tagging-panel.component';
import {
  MatTabGroup,
  MatTab,
  MatTabLabel,
  MatTabChangeEvent,
} from '@angular/material/tabs';
import { LoadingBarComponent } from '../../../shared/components/loading-bar/loading-bar.component';
import { CreatedTagsComponent } from '../../../shared/components/created-tags/created-tags.component';
import { CreatedTagsMoreComponent } from '../../../shared/components/created-tags-more/created-tags-more.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { AbstractComponent } from 'src/app/shared/directives/abstract.component.directive';
import { VideoHeaderPanelComponent } from 'src/app/shared/components/video-header-panel/video-header-panel.component';
import { MatTooltip } from '@angular/material/tooltip';

const TRIM_ANNOTATION_TIMEOUT = 250;

@Component({
  selector: 'cmv-video-tagging-view',
  templateUrl: './video-tagging-view.component.html',
  styleUrls: ['./video-tagging-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: TAGS_ANIMATION,
  imports: [
    NgIf,
    VideoPageLayoutComponent,
    VideoPlayerWrapperComponent,
    VideoControlPanelComponent,
    TrimmingPanelComponent,
    TaggingPanelComponent,
    NgTemplateOutlet,
    MatTabGroup,
    MatTab,
    MatTabLabel,
    LoadingBarComponent,
    CreatedTagsComponent,
    CreatedTagsMoreComponent,
    AsyncPipe,
    TranslateModule,
    MatIcon,
    MatIconButton,
    VideoHeaderPanelComponent,
    MatTooltip,
  ],
})
export class VideoTaggingViewComponent
  extends AbstractComponent
  implements OnInit, OnDestroy
{
  @ViewChild('videoPlayer')
  videoPlayer: VideoPlayerWrapperComponent;

  boundedCreateTag: Function;
  shouldAutoplay: boolean;
  stopInfo: { at: number; ignore: number } | null;

  tagTabList = TagTabList;
  currentTab = TagTabList.ADD_TAGS;

  readonly shareableList$ = this.store.pipe(select($shareableList));

  readonly annotations$ = this.store.pipe(select($filteredAnnotations));
  readonly recordingVideos$ = this.store.pipe(select($currentRecordingVideos));
  readonly downloadVideos$ = this.store.pipe(
    select($currentRecordingDownloadVideos),
  );
  readonly currentRecordingLiveVideos$ = this.store.pipe(
    select($currentRecordingLiveVideos),
  );
  readonly isAnyCurrentRecordingVideoUserRemuxing$ = this.store.pipe(
    select($isAnyCurrentRecordingVideoUserRemuxing),
  );

  readonly loadingResources$ = this.store.pipe(
    select($videoTaggingViewLoading),
  );
  readonly currentVideo$ = this.store.pipe(select($currentVideo));
  readonly currentVideoUrl$ = this.store.pipe(select($currentSignedUrl));
  readonly currentVideoLoading$ = this.store.pipe(select($currentVideoLoading));

  readonly isSharingEnabled$ = this.store.pipe(select($isSharingEnabled));

  readonly xlBreakPoint$ = this.store.pipe(select($xlBreakPoint));
  readonly mBreakPoint$ = this.store.pipe(select($mBreakPoint));

  readonly match$: Observable<Recording> = this.store.pipe(
    select($currentRecording),
    filter(recording => !!recording),
  );

  readonly canDownloadAnnotationList$ = this.store.pipe(
    select($canDownloadAnnotationList),
  );

  readonly isAnnotationListRendering$ = this.store.pipe(
    select($isAnnotationListRendering),
  );
  readonly currentRecordingId$ = this.store.select($currentRecordingId);
  readonly isTrimming$ = this.store.pipe(select($isTrimming));
  readonly currentAnnotation$ = this.store.select($currentAnnotation);
  readonly trimmingDisabled$ = this.store.select($trimmingDisabled);
  readonly isMultiselectEnabled$ = this.store.select($isMultiselectEnabled);
  readonly showFilter$ = this.store.select($showFilter);
  readonly multiselectIcon$ = this.isMultiselectEnabled$.pipe(
    map(isEnabled =>
      isEnabled ? 'cmv-multiselect-cancel' : 'cmv-multiselect',
    ),
  );

  readonly filterIcon$ = this.store.pipe(
    select($isAnnotationFilterEnabled),
    map(isFilterEnabled =>
      isFilterEnabled ? 'cmv-filter-cancel' : 'cmv-filter',
    ),
  );

  currentOffset$: Observable<OffsetModel | null> = this.store.pipe(
    select($currentAnnotationOffset),
    withLatestFrom(this.store.select($isTrimming)),
    map(([currVidOffset, isTrimming]) =>
      currVidOffset === undefined || !isTrimming
        ? null
        : {
            ...currVidOffset,
            jumpToStart: this.jumpToStart,
            jumpToEnd: this.jumpToEnd,
          },
    ),
  );

  calcAnnotationValue$ = this.store.pipe(select($currentAnnotationValue));

  jumpToStart = true;
  jumpToEnd = false;

  newCurrentOffset$: BehaviorSubject<OffsetModel> = new BehaviorSubject({
    jumpToStart: this.jumpToStart,
    jumpToEnd: this.jumpToEnd,
  });

  playerOffset$: Observable<OffsetModel | null> = this.currentOffset$.pipe(
    switchMap(currentOffset =>
      this.newCurrentOffset$
        .asObservable()
        .pipe(
          map(newCurrentOffset =>
            newCurrentOffset.start != null && newCurrentOffset.end != null
              ? newCurrentOffset
              : currentOffset,
          ),
        ),
    ),
  );

  playerOffsetIsValid$: Observable<boolean> = this.playerOffset$.pipe(
    switchMap(offset =>
      this.store.select($currentVideo).pipe(
        map(video => {
          if (offset === null) {
            return true;
          }

          if (offset?.start === undefined || offset?.end === undefined) {
            return false;
          }

          const start = Number(offset.start);
          const end = Number(offset.end);

          if (end < 0 || start > video.trueDuration || end - start <= 0) {
            return false;
          }

          return true;
        }),
      ),
    ),
  );

  trimmingOffset$: Observable<TrimmingInputModel> = this.newCurrentOffset$.pipe(
    switchMap((newCurrentOffset: OffsetModel) =>
      this.currentOffset$.pipe(
        map((currentOffset: OffsetModel) => [newCurrentOffset, currentOffset]),
      ),
    ),
    map(([newCurrentOffset, currentOffset]: [OffsetModel, OffsetModel]) =>
      newCurrentOffset.start != null && newCurrentOffset.end != null
        ? newCurrentOffset
        : currentOffset,
    ),
    switchMap(offset =>
      this.calcAnnotationValue$.pipe(
        filter(
          (calcAnnotationValue): calcAnnotationValue is TrimmingInputModel =>
            !!calcAnnotationValue,
        ),
        map(
          (calcAnnotationValue): TrimmingInputModel => ({
            ...calcAnnotationValue,
            tagStart: offset.start!,
            tagEnd: offset.end!,
          }),
        ),
      ),
    ),
  );

  constructor(
    private readonly router: Router,
    private readonly store: Store<AppState>,
    public readonly dialog: MatDialog,
  ) {
    super();

    this.xlBreakPoint$.pipe(this.untilDestroyed()).subscribe(isMobileSize => {
      if (isMobileSize && this.currentTab === TagTabList.ADD_TAGS) {
        this.store.dispatch(disableMultiselectAction());
      }
    });
  }

  ngOnInit(): void {
    this.boundedCreateTag = this.createTag.bind(this);
  }

  ngOnDestroy(): void {
    this.store.dispatch(disableMultiselectAction());
    this.store.dispatch(disableAnnotationFilterAction());
  }

  toggleMultiselect() {
    this.store.dispatch(toggleMultiselectAction());
  }

  toggleFilter() {
    this.store.dispatch(toggleAnnotationFilterAction());
  }

  onPlayerReady(): void {
    this.currentVideo$
      .pipe(
        withLatestFrom(this.currentAnnotation$),
        filter(
          ([video, annotation]) =>
            !!video &&
            !!annotation &&
            !!this.videoPlayer?.player?.api.getDefaultMedia(),
        ),
        take(1),
      )
      .subscribe(([currentVideo, currentAnnotation]) => {
        if (currentAnnotation) {
          const annotationStart =
            currentAnnotation.timeStamp -
            currentVideo.startAt +
            currentAnnotation.from;
          this.videoPlayer.setCurrentTime(annotationStart);
          this.stopInfo = {
            at: annotationStart + currentAnnotation.to,
            ignore: annotationStart,
          };
        }
      });
  }

  tabIndexChange({ index }: MatTabChangeEvent): void {
    this.currentTab = index;
    if (index === TagTabList.ADD_TAGS) {
      this.store.dispatch(disableMultiselectAction());
    }
  }

  videoCurrentTimeChange(currentTime: number): void {
    if (this.stopInfo) {
      if (Math.floor(currentTime / 1000) === this.stopInfo.at) {
        this.videoPlayer.player.api.pause();
      }
    }
  }

  videoSeeked(time: number) {
    if (this.stopInfo?.ignore !== Math.floor(time / 1000)) {
      this.stopInfo = null;
    }
  }

  getFullMatchName(recording: Recording): string {
    return getFullMatchName(recording);
  }

  createTag(tag: Partial<Annotation>, inOverlay: boolean = false): void {
    const currentTime = this.videoPlayer
      ? Math.floor(this.videoPlayer.player.getCurrentTime() * 1000) / 1000
      : 0;

    this.store.dispatch(
      prepareAnnotationPostRequestAction({
        annotation: tag,
        currentTime,
        inOverlay,
      }),
    );
  }

  deleteTag(annotation: Annotation): void {
    this.store.dispatch(deleteAnnotationRequestAction({ annotation }));
  }

  editAnnotation(annotation: Annotation): void {
    this.store.dispatch(
      editAnnotationNameRequestAction({
        id: annotation.id,
        name: annotation.name,
        description: annotation.description,
      }),
    );
  }

  selectTag(annotation: Annotation): void {
    this.isTrimming$.pipe(take(1)).subscribe(isTrimming => {
      if (isTrimming) {
        this.videoPlayer.player.api.pause();
        this.newCurrentOffset$.next({
          jumpToStart: true,
          jumpToEnd: false,
        });
        this.router.navigate(
          [RoutePath.Platform, RoutePath.Recordings, RoutePath.PlayedMatch],
          {
            queryParams: {
              [QueryParamNames.AnnotationId]: annotation.id,
              [QueryParamNames.RecordingId]: annotation.recordingId,
              [QueryParamNames.Trimming]: true,
            },
          },
        );
      } else {
        this.currentVideo$.pipe(first()).subscribe(currentVideo => {
          const annotationStart =
            annotation.timeStamp - currentVideo.startAt + annotation.from;
          this.videoPlayer.setCurrentTime(annotationStart);
          this.stopInfo = {
            at: annotationStart + annotation.to,
            ignore: annotationStart,
          };
        });
      }
    });
  }

  trimAnnotation(newValues: { from: number; to: number }): void {
    this.currentAnnotation$.pipe(take(1)).subscribe(annotation => {
      this.store.dispatch(
        prepareCurrentAnnotationTrimmingRequestAction({ newValues }),
      );
      this.router.navigate(
        [RoutePath.Platform, RoutePath.Recordings, RoutePath.PlayedMatch],
        {
          queryParams: {
            [QueryParamNames.RecordingId]: annotation!.recordingId,
          },
        },
      );
      this.newCurrentOffset$.next({
        jumpToStart: false,
        jumpToEnd: false,
      });

      setTimeout(() => {
        this.videoPlayer.setCurrentTime(newValues.from);
      }, TRIM_ANNOTATION_TIMEOUT);
    });
  }

  cancelTrim() {
    this.currentRecordingId$.pipe(take(1)).subscribe(recordingId => {
      this.router.navigate(
        [RoutePath.Platform, RoutePath.Recordings, RoutePath.PlayedMatch],
        {
          queryParams: {
            [QueryParamNames.RecordingId]: recordingId,
          },
        },
      );
    });
    this.newCurrentOffset$.next({
      jumpToStart: true,
      jumpToEnd: false,
    });
  }

  redirectToTrimming(id: string, recordingId: string): void {
    this.videoPlayer.player.api.pause();
    this.newCurrentOffset$.next({
      jumpToStart: true,
      jumpToEnd: false,
    });

    this.router.navigate(
      [RoutePath.Platform, RoutePath.Recordings, RoutePath.PlayedMatch],
      {
        queryParams: {
          [QueryParamNames.AnnotationId]: id,
          [QueryParamNames.RecordingId]: recordingId,
          [QueryParamNames.Trimming]: true,
        },
      },
    );
  }

  changeSrc(
    {
      currentVideo,
      video,
    }: {
      currentVideo: CurrentVideoModel;
      video: Video;
    },
    inFullscreen = false,
  ): void {
    if (currentVideo.id === video.id) {
      return;
    }

    const prevVideoTime = this.videoPlayer
      ? this.videoPlayer.player.getCurrentTime()
      : 0;
    if (this.videoPlayer) {
      this.shouldAutoplay = this.videoPlayer.player.api
        ?.getDefaultMedia()
        .state.includes('playing');
    }
    this.store.dispatch(changeCurrentVideoAction({ video, inFullscreen }));

    if (this.videoPlayer) {
      this.videoPlayer.player.setCurrentTime(
        prevVideoTime + currentVideo.startAt - video.startAt,
      );
    }
  }

  recalculateTag(start: number, end: number): void {
    if (this.newCurrentOffset$.value) {
      this.newCurrentOffset$.next({
        ...this.newCurrentOffset$.value,
        end,
        start,
      });
    }
  }

  seekWithHandleMove(seekTo: SEEK_TO): void {
    if (seekTo === SEEK_TO.START) {
      this.jumpToStart = true;
      this.jumpToEnd = !this.jumpToStart;
    } else {
      this.jumpToEnd = true;
      this.jumpToStart = !this.jumpToEnd;
    }
  }
}
