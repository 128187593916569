<div class="created-tags-more">
  <div class="buttons-wrapper">
    <button
      class="cmv-more-button"
      mat-icon-button
      (click)="$event.stopPropagation()"
      [matMenuTriggerFor]="detailMenu"
    >
      <img src="assets/icons/more_icon_ver.svg" />
    </button>
  </div>
  <mat-menu class="cmv-menu-container" #detailMenu="matMenu">
    <div (keydown)="$event.stopPropagation()">
      @if (isMultiselectEnabled$ | async) {
        <!-- Add to playlist -->
        <button
          [disabled]="isSelectionEmpty$ | async"
          class="cmv-menu-item"
          mat-menu-item
        >
          <span class="cmv-h4">
            {{ 'basic.addToPlaylist' | translate }}
          </span>
          <span class="menu-icon--wrapper">
            <mat-icon
              class="modal-icon cmv-gray-icon"
              svgIcon="cmv-add-playlist"
            ></mat-icon>
          </span>
        </button>
        <!-- Share tags -->
        <button
          [disabled]="selectionEmptyOrContainsSharedAnnotations$ | async"
          class="cmv-menu-item"
          mat-menu-item
        >
          <span class="cmv-h4">
            {{ 'basic.shareAnnotation' | translate }}
          </span>
          <span class="menu-icon--wrapper">
            <mat-icon
              class="modal-icon cmv-white-icon"
              svgIcon="cmv-share"
            ></mat-icon>
          </span>
        </button>
        <!-- Download tags -->
        <button
          *ngIf="currentRecordingHasVideo$ | async"
          [disabled]="isSelectionEmpty$ | async"
          class="cmv-menu-item"
          mat-menu-item
        >
          <span class="cmv-h4">
            {{ downloadButtonLabel | translate }}
          </span>
          <span class="menu-icon--wrapper">
            <mat-icon
              class="modal-icon cmv-white-icon"
              [svgIcon]="downloadButtonIcon"
            ></mat-icon>
          </span>
        </button>
        <!-- Remove tags -->
        <button
          [disabled]="selectionEmptyOrContainsSharedAnnotations$ | async"
          class="cmv-menu-item"
          mat-menu-item
        >
          <span class="cmv-h4">
            {{ 'basic.removeTag' | translate }}
          </span>
          <span class="menu-icon--wrapper">
            <mat-icon
              class="modal-icon cmv-gray-icon"
              svgIcon="cmv-delete"
            ></mat-icon>
          </span>
        </button>
      } @else {
        <button
          class="cmv-menu-item"
          (click)="shareAnnotationList(shareableList)"
          mat-menu-item
        >
          <span class="cmv-h4">
            {{ 'basic.shareList' | translate }}
          </span>
          <span class="menu-icon--wrapper">
            <mat-icon
              class="modal-icon cmv-gray-icon"
              svgIcon="cmv-share"
            ></mat-icon>
          </span>
        </button>
        <button
          *ngIf="!annotationsEmpty && (currentRecordingHasVideo$ | async)"
          class="cmv-menu-item"
          (click)="downloadAnnotationList(shareableList)"
          mat-menu-item
        >
          <span class="cmv-h4">
            {{ downloadButtonLabel | translate }}
          </span>
          <span class="menu-icon--wrapper">
            <mat-icon
              class="modal-icon cmv-gray-icon"
              [svgIcon]="downloadButtonIcon"
            ></mat-icon>
          </span>
        </button>
      }
    </div>
  </mat-menu>
</div>
