import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ManualSchedulingFormComponent } from '../../../shared/components/manual-scheduling-form/manual-scheduling-form.component';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-create-recording-view',
  templateUrl: './create-recording-view.component.html',
  styleUrls: ['./create-recording-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PageLayoutComponent,
    ManualSchedulingFormComponent,
    HeaderComponent,
    TranslateModule,
  ],
})
export class CreateRecordingViewComponent {
  constructor() {}
}
