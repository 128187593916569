import { Action, createReducer, on } from '@ngrx/store';
import { AnnotationState } from '../../models/app.state';
import {
  deleteAnnotationFailureAction,
  deleteAnnotationRequestAction,
  deleteAnnotationSuccessAction,
  downloadAnnotationSuccessAction,
  editAnnotationNameFailureAction,
  editAnnotationNameRequestAction,
  editAnnotationNameSuccessAction,
  editAnnotationTrimmingFailureAction,
  editAnnotationTrimmingRequestAction,
  editAnnotationTrimmingSuccessAction,
  getAnnotationsFailureAction,
  getAnnotationsRecordingFailureAction,
  getAnnotationsRecordingRequestAction,
  getAnnotationsRecordingSuccessAction,
  getAnnotationsRequestAction,
  getAnnotationsSuccessAction,
  pollAnnotationsFailureAction,
  pollAnnotationsRequestAction,
  pollAnnotationsSuccessAction,
  postAnnotationFailureAction,
  postAnnotationRequestAction,
  postAnnotationSuccessAction,
  refreshAnnotationThumbnailAction,
} from '../actions/annotation.actions';

const initialState: AnnotationState = {
  entities: {},
  errors: [],
  loading: false,
  loaded: false,
};

const reducer = createReducer(
  initialState,
  on(
    getAnnotationsRequestAction,
    pollAnnotationsRequestAction,
    postAnnotationRequestAction,
    deleteAnnotationRequestAction,
    editAnnotationTrimmingRequestAction,
    editAnnotationNameRequestAction,
    getAnnotationsRecordingRequestAction,
    state => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    postAnnotationSuccessAction,
    editAnnotationNameSuccessAction,
    editAnnotationTrimmingSuccessAction,
    downloadAnnotationSuccessAction,
    (state, { annotation }) => ({
      ...state,
      entities: {
        ...state.entities,
        [annotation.id]: { ...annotation },
      },
      loading: false,
      loaded: true,
      errors: [],
    }),
  ),
  on(
    getAnnotationsSuccessAction,
    pollAnnotationsSuccessAction,
    getAnnotationsRecordingSuccessAction,
    (state, { annotations }) => ({
      ...state,
      entities: annotations.reduce(
        (acc, annotation) => ({
          ...acc,
          [annotation.id]: { ...annotation },
        }),
        {},
      ),
      loading: false,
      loaded: true,
      errors: [],
    }),
  ),
  on(deleteAnnotationSuccessAction, (state, { id }) => {
    const { [id]: removed, ...entities } = state.entities;
    return { ...state, entities, loading: false, loaded: true, errors: [] };
  }),
  on(
    getAnnotationsFailureAction,
    pollAnnotationsFailureAction,
    postAnnotationFailureAction,
    editAnnotationNameFailureAction,
    editAnnotationTrimmingFailureAction,
    deleteAnnotationFailureAction,
    getAnnotationsRecordingFailureAction,
    (state, { errors }) => ({
      ...state,
      loading: false,
      errors: [...state.errors, ...errors],
    }),
  ),
  on(refreshAnnotationThumbnailAction, (state, { annotationId, url }) => ({
    ...state,
    entities: {
      ...state.entities,
      ...(state.entities.hasOwnProperty(annotationId)
        ? {
            [annotationId]: { ...state.entities[annotationId], thumbnail: url },
          }
        : {}),
    },
  })),
);

export function annotationReducer(
  state: AnnotationState,
  action: Action,
): AnnotationState {
  return reducer(state, action);
}
