import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'time',
  standalone: true,
})
export class TimePipe implements PipeTransform {
  constructor() {}
  transform(value: number): string {
    return moment.unix(value).format('HH:mm');
  }
}
