import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RoutePath } from '../app.constants';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, TranslateModule],
})
export class Error404Component {
  get routerLink() {
    return ['/', RoutePath.Landing];
  }
}
