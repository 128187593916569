import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../models/app.state';
import { LiveTaggingModes } from '../../models/current-selections.model';
import { changeLiveTaggingModeAction } from '../../store/actions/current-selections.actions';
import { $taggingModeSwitchDisabled } from '../../store/selectors/recording.selectors';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-live-tagging-controls',
  templateUrl: './live-tagging-controls.component.html',
  styleUrls: ['./live-tagging-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatSlideToggle, AsyncPipe, TranslateModule],
})
export class LiveTaggingControlsComponent {
  @Input() isStreamTagging: boolean;

  readonly taggingModeSwitchDisabled$ = this.store.pipe(
    select($taggingModeSwitchDisabled),
  );

  constructor(private readonly store: Store<AppState>) {}

  changeLiveTaggingMode(): void {
    this.store.dispatch(
      changeLiveTaggingModeAction({
        mode: this.isStreamTagging
          ? LiveTaggingModes.PITCH
          : LiveTaggingModes.STREAM,
      }),
    );
  }
}
