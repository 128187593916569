import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  ElementRef,
  ViewChild,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { Annotation, Playlist } from '../../../../../api/models';
import { AppState } from '../../../models/app.state';
import { EditModalModel } from '../../../models/edit-modal.model';
import {
  addToPlaylistRequestAction,
  postPlaylistRequestAction,
  removeFromPlaylistRequestAction,
} from '../../../store/actions/playlist-list.actions';
import { $playlistEntities } from '../../../store/selectors/playlist-list.selectors';
import {
  EditModalComponent,
  EditModalTypes,
} from '../edit-modal/edit-modal.component';
import { MatIcon } from '@angular/material/icon';
import { NgFor } from '@angular/common';
import { CheckboxComponent } from '../../checkbox/checkbox.component';
import { TranslateModule } from '@ngx-translate/core';

interface ModalPlaylist {
  member: boolean;
  playlist: Playlist;
}

@Component({
  selector: 'cmv-add-to-playlist-modal',
  templateUrl: './add-to-playlist-modal.component.html',
  styleUrls: ['./add-to-playlist-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon, NgFor, CheckboxComponent, TranslateModule],
})
export class AddToPlaylistModalComponent implements OnDestroy, AfterViewInit {
  title = '';
  playlists: ModalPlaylist[] | null = null;
  readonly unsubscribe$ = new Subject<void>();

  @ViewChild('elementToFocus')
  elementToFocus: ElementRef;

  constructor(
    private readonly dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: {
      annotation: Annotation;
      fromPlaylistId: string | undefined;
    },
    private readonly cd: ChangeDetectorRef,
    private readonly store: Store<AppState>,
  ) {
    this.store
      .pipe(
        select($playlistEntities),
        takeUntil(this.unsubscribe$.asObservable()),
        distinctUntilChanged(),
      )
      .subscribe((playlist: Playlist[]) => {
        this.playlists = playlist.map(item => {
          let member = item.annotations
            ? item.annotations.some(
                (ann: Annotation) => ann.id === this.data.annotation.id,
              )
            : false;

          if (
            this.playlists &&
            !this.playlists.find(i => i.playlist.id === item.id)
          ) {
            this.store.dispatch(
              addToPlaylistRequestAction({
                annotationId: this.data.annotation.id,
                playlistId: item.id,
                annotationName: this.data.annotation.name,
                playlistName: item.name,
                fromPlaylistId: this.data.fromPlaylistId,
              }),
            );
          }

          return {
            member,
            playlist: item,
          };
        });
        this.cd.markForCheck();
      });
    this.title = data.annotation.name;
  }

  openCreatePlaylistModal(): void {
    const dialogInstance = this.dialog.open(EditModalComponent, {
      data: {
        title: '',
        type: EditModalTypes.PLAYLIST_CREATE,
      },
    });

    if (dialogInstance != null) {
      dialogInstance
        .afterClosed()
        .pipe(take(1))
        .subscribe((value: EditModalModel) => {
          if (value != null && value.title.length > 0) {
            this.store.dispatch(
              postPlaylistRequestAction({ playlist: { name: value.title } }),
            );
          }
        });
    }
  }

  togglePlaylist(playlist: ModalPlaylist): void {
    if (playlist.member) {
      this.store.dispatch(
        removeFromPlaylistRequestAction({
          annotationId: this.data.annotation.id,
          playlistId: playlist.playlist.id,
          annotationName: this.data.annotation.name,
          playlistName: playlist.playlist.name,
        }),
      );
    } else {
      this.store.dispatch(
        addToPlaylistRequestAction({
          annotationId: this.data.annotation.id,
          playlistId: playlist.playlist.id,
          annotationName: this.data.annotation.name,
          playlistName: playlist.playlist.name,
          fromPlaylistId: this.data.fromPlaylistId,
        }),
      );
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementToFocus.nativeElement.focus();
    }, 0);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeModal(): void {
    this.dialog.closeAll();
  }
}
