import { APP_ICONS } from './app.constants';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { registerIconsFactory } from './app.utils';
import { AppState } from './shared/models/app.state';
import { select, Store } from '@ngrx/store';
import { $showRotateDeviceMessage } from './shared/store/selectors/ui-flags.selectors';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div style="height: 100%">
      <router-outlet></router-outlet>
    </div>
  `,
  imports: [RouterOutlet],
})
export class AppComponent {
  readonly showRotateDeviceMessage$ = this.store.pipe(
    select($showRotateDeviceMessage),
  );

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
    private readonly store: Store<AppState>,
  ) {
    registerIconsFactory(this.matIconRegistry, this.sanitizer)(APP_ICONS);
  }
}
