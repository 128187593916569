import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import moment from 'moment';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-separator',
  templateUrl: './separator.component.html',
  styleUrls: ['./separator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, TranslateModule],
})
export class SeparatorComponent {
  @Input() date: number;

  get day(): string {
    return moment.unix(this.date).format('D');
  }

  get monthYear(): string {
    return moment.unix(this.date).format('MMMM YYYY');
  }

  get isToday(): boolean {
    return moment.unix(this.date).isSame(moment(), 'day');
  }
}
