<ng-container *ngIf="match$ | async as match">
  <cmv-video-page-layout
    [showHeader]="match.status"
    [title]="'components.liveMatchTagging.title' | translate"
    [subtitle]="'components.liveMatchTagging.subtitle' | translate"
    [loadingResources]="loadingResources$ | async"
  >
    <ng-container left-column>
      <cmv-video-player-wrapper
        class="video-player"
        *ngIf="isStreamTagging$ | async"
        [isMissingVideo]="(currentVideo$ | async) === null"
        [notInRange]="(playerOffsetIsValid$ | async) === false"
        [loading]="currentVideoLoading$ | async"
        [offset]="playerOffset$ | async"
        [autoplay]="shouldAutoplay"
        [videoUrl]="currentVideoUrl$ | async"
        [createTag]="boundedCreateTag"
        [currentVideo]="currentVideo$ | async"
        [videoSources]="recordingVideos$ | async"
        (videoCurrentTime)="videoCurrentTimeChange($event)"
        (changeSrc)="changeSrc($event, true)"
        (seeked)="videoSeeked($event)"
        (playerReady)="onPlayerReady()"
        #videoPlayer
      ></cmv-video-player-wrapper>

      <div class="tagging-controls">
        <cmv-live-tagging-controls [isStreamTagging]="isStreamTagging$ | async">
          <div class="tagging-controls--right">
            <cmv-change-src-menu
              *ngIf="isStreamTagging$ | async"
              [currentVideo]="currentVideo$ | async"
              [videoSources]="recordingVideos$ | async"
              (changeSrc)="changeSrc($event)"
            ></cmv-change-src-menu>
            <cmv-recording-info></cmv-recording-info>
          </div>
        </cmv-live-tagging-controls>
      </div>
      <div *ngIf="isTrimming$ | async" class="trimming-panel-wrapper">
        <cmv-trimming-panel
          [videoStartAt]="(currentVideo$ | async).startAt"
          [trimmingValues]="trimmingOffset$ | async"
          (submitChange)="trimAnnotation($event)"
          (seekTo)="seekWithHandleMove($event)"
          (valueRangeChange)="recalculateTag($event.from, $event.to)"
          (cancel)="cancelTrim()"
        ></cmv-trimming-panel>
      </div>
      <div
        class="tagging-pitch"
        *ngIf="
          (isStreamTagging$ | async) === false && (largeDeviceTagging$ | async)
        "
      >
        <div class="tagging-panel-wrapper" *ngIf="match$ | async">
          <cmv-tagging-panel
            (tagCreated)="createTag($event)"
          ></cmv-tagging-panel>
        </div>
      </div>
    </ng-container>
    <ng-container right-column>
      <ng-container *ngIf="largeDeviceTagging$ | async">
        <div
          class="tagging-pitch large-device-resolution-tagging"
          *ngIf="isStreamTagging$ | async"
        >
          <div class="tagging-panel-wrapper" *ngIf="match$ | async">
            <cmv-tagging-panel
              (tagCreated)="createTag($event)"
            ></cmv-tagging-panel>
          </div>
        </div>
        <div class="created-tags-live">
          <div
            class="cmv-section-header with-loading"
            [class.active]="isAnnotationListRendering$ | async"
          >
            <div class="cmv-section-header--inner">
              <div class="cmv-h4">
                {{ 'components.createdTags' | translate }}
              </div>
              <div class="create-tags-more-wrapper">
                <cmv-created-tags-more
                  *ngIf="isSharingEnabled$ | async"
                  [shareableList]="shareableList$ | async"
                  [annotationsEmpty]="(annotations$ | async).length === 0"
                  [isDownloadPrepared]="canDownloadAnnotationList$ | async"
                ></cmv-created-tags-more>
              </div>
            </div>
            <cmv-loading-bar></cmv-loading-bar>
          </div>
          <ng-container *ngTemplateOutlet="createdTags"></ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="(largeDeviceTagging$ | async) === false">
        <mat-tab-group
          class="small-device-resolution-tagging"
          animationDuration="0ms"
        >
          <mat-tab
            label="{{ 'components.taggingPanel.addTags' | translate }}"
            class="mat-tab-tagging"
          >
            <div class="tagging-live">
              <div class="tagging-panel-wrapper" *ngIf="match$ | async">
                <cmv-tagging-panel
                  (tagCreated)="createTag($event)"
                ></cmv-tagging-panel>
              </div>
            </div>
          </mat-tab>
          <mat-tab labelClass="create-tags-label">
            <ng-template mat-tab-label class="mat-tab-label-with-icon">
              <div class="tab-label-with-button">
                {{ 'components.createdTags' | translate }}
              </div>
              <div
                class="create-tags-more-wrapper create-tags-more-wrapper-mobile"
              >
                <cmv-created-tags-more
                  *ngIf="isSharingEnabled$ | async"
                  [shareableList]="shareableList$ | async"
                  [annotationsEmpty]="(annotations$ | async).length === 0"
                  [isDownloadPrepared]="canDownloadAnnotationList$ | async"
                ></cmv-created-tags-more>
              </div>
              <div
                class="created-tags-mobile-loading-bar"
                *ngIf="isAnnotationListRendering$ | async"
              >
                <cmv-loading-bar></cmv-loading-bar>
              </div>
            </ng-template>
            <div class="created-tags-live">
              <ng-container *ngTemplateOutlet="createdTags"></ng-container>
            </div>
          </mat-tab>
        </mat-tab-group>
      </ng-container>
    </ng-container>
  </cmv-video-page-layout>
</ng-container>

<ng-template #createdTags>
  <cmv-created-tags
    [showEmptyList]="true"
    [annotations]="annotations$ | async"
    (deleteTag)="deleteTag($event)"
    (trimVideo)="redirectToTrimming($event.id, $event.recordingId)"
    (editAnnotation)="editAnnotation($event)"
    (selectedWidgetIdChange)="selectTag($event.annotation)"
    [hideDownloadTag]="false"
    [trimmingDisabled]="(isStreamTagging$ | async) === false"
  ></cmv-created-tags>
</ng-template>
