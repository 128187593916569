import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs';
import { Recording } from 'src/api/models';
import { sortCurrentMatchDay } from 'src/app/app.utils';
import { EmptyTrashModalComponent } from 'src/app/shared/components/modal/empty-trash-modal/empty-trash-modal.component';
import { AppState } from 'src/app/shared/models/app.state';
import { emptyTrashRequestAction } from 'src/app/shared/store/actions/trash.actions';
import {
  $recordersLoaded,
  $sortedRecorders,
} from 'src/app/shared/store/selectors/recorders.selectors';
import {
  $recordingsInTrash,
  $trashLoaded,
} from 'src/app/shared/store/selectors/trash.selectors';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { NgIf, NgFor, AsyncPipe, KeyValuePipe } from '@angular/common';
import { FreeSpaceVisualizerComponent } from '../../../shared/components/free-space-visualizer/free-space-visualizer.component';
import { MatchDayComponent } from '../../../shared/components/match-day/match-day.component';
import { LoadingStateComponent } from '../../../shared/components/loading-state/loading-state.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-trash',
  templateUrl: './trash.component.html',
  styleUrls: ['./trash.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    HeaderComponent,
    NgIf,
    NgFor,
    FreeSpaceVisualizerComponent,
    MatchDayComponent,
    LoadingStateComponent,
    AsyncPipe,
    KeyValuePipe,
    TranslateModule,
  ],
})
export class TrashComponent {
  readonly recordingsInTrash$ = this.store.pipe(select($recordingsInTrash));
  readonly trashLoaded$ = this.store.pipe(select($trashLoaded));

  readonly recorders$ = this.store.pipe(select($sortedRecorders));
  readonly recordersLoaded$ = this.store.pipe(select($recordersLoaded));

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog,
  ) {}

  sortDates(matchDay: { [key: string]: Recording[] }) {
    return sortCurrentMatchDay(matchDay);
  }

  nameTrackFn = (_: number, item: Recording) => item.id;

  emptyTrash(): void {
    const dialogInstance = this.dialog.open(EmptyTrashModalComponent);

    if (dialogInstance != null) {
      dialogInstance
        .afterClosed()
        .pipe(take(1))
        .subscribe(value => {
          if (value) {
            this.store.dispatch(emptyTrashRequestAction());
          }
        });
    }
  }
}
