import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Playlist, PlaylistStatus } from '../../../../api/models';
import { EditModalModel } from '../../models/edit-modal.model';
import {
  EditModalComponent,
  EditModalTypes,
} from '../modal/edit-modal/edit-modal.component';
import {
  INPUT_TYPE,
  RemoveModalComponent,
} from '../modal/remove-modal/remove-modal.component';
import { QueryParamNames, RoutePath } from 'src/app/app.constants';
import { NgIf, NgClass } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatIconButton } from '@angular/material/button';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { LoadingBarComponent } from '../loading-bar/loading-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  deletePlaylistRequestAction,
  putPlaylistRequestAction,
  renderPlaylistRequestAction,
} from '../../store/actions/playlist-list.actions';
import { AppState } from '../../models/app.state';
import { Store } from '@ngrx/store';
import { MenuItemComponent } from '../menu-item/menu-item.component';

@Component({
  selector: 'cmv-playlist-title',
  templateUrl: './playlist-title.component.html',
  styleUrls: ['./playlist-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    RouterLink,
    NgClass,
    MatIconButton,
    MatMenuTrigger,
    MatMenu,
    LoadingBarComponent,
    TranslateModule,
    MenuItemComponent,
  ],
})
export class PlaylistTitleComponent {
  @Input() playlist: Playlist;
  @Input() navigation = true;

  @Output() playlistRemoved: EventEmitter<Playlist> =
    new EventEmitter<Playlist>();

  readonly PlaylistStatus = PlaylistStatus;

  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
  ) {}

  downloadHandler(): void {
    if (this.playlist.status === PlaylistStatus.DONE && this.playlist.url) {
      window.open(this.playlist.url, '_blank');
    } else if (this.playlist.status !== PlaylistStatus.RENDERING) {
      this.store.dispatch(
        renderPlaylistRequestAction({ id: this.playlist.id }),
      );
    }
  }

  editPlaylistName(name: string): void {
    this.store.dispatch(
      putPlaylistRequestAction({
        playlist: { ...this.playlist, name: name },
      }),
    );
  }

  deletePlaylist(): void {
    this.store.dispatch(
      deletePlaylistRequestAction({ playlist: this.playlist }),
    );
    this.playlistRemoved.emit(this.playlist);
  }

  openModal(type: string): void {
    let dialog;
    let handleFunction;

    switch (type) {
      case 'EditModal':
        dialog = this.dialog.open(EditModalComponent, {
          data: {
            title: this.playlist.name,
            type: EditModalTypes.PLAYLIST_EDIT,
          },
        });
        handleFunction = (value: EditModalModel) => {
          if (value != null && this.playlist.name !== value.title) {
            this.editPlaylistName(value.title);
          }
        };
        break;
      case 'RemoveModal':
        dialog = this.dialog.open(RemoveModalComponent, {
          data: {
            title: this.playlist.name,
            type: INPUT_TYPE.PLAYLIST,
          },
        });
        handleFunction = (value: boolean) => {
          if (value) {
            this.deletePlaylist();
          }
        };
        break;
    }

    if (dialog != null) {
      dialog.afterClosed().pipe(take(1)).subscribe(handleFunction);
    }
  }

  menuButtonClickHandler(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  get routerLink() {
    if (!this.navigation) {
      return null;
    }

    return this.playlist.annotations?.filter(a => a.show).length > 0
      ? ['/', RoutePath.Platform, RoutePath.Playlists, RoutePath.Clip]
      : null;
  }

  get routerQueryParameters() {
    if (!this.navigation) {
      return null;
    }

    const filteredAnnotations = this.playlist.annotations?.filter(a => a.show);
    return filteredAnnotations?.length > 0
      ? {
          [QueryParamNames.PlaylistId]: this.playlist.id,
          [QueryParamNames.AnnotationId]: filteredAnnotations[0].id,
        }
      : null;
  }

  get canDownload(): boolean {
    return (
      this.playlist.annotations &&
      this.playlist.annotations.filter(a => a.show).length > 0
    );
  }

  get playlistLength(): number {
    return (this.playlist.annotations ?? []).filter(a => a.show).length;
  }

  get downloadButtonLabel(): string {
    return this.playlist.status === PlaylistStatus.RENDERING
      ? 'components.playlistCollection.rendering'
      : 'basic.downloadPlaylist';
  }

  get downloadButtonIcon(): string {
    switch (this.playlist?.status) {
      case PlaylistStatus.RENDERING:
        return 'cmv-loading';
      case PlaylistStatus.FAILED:
        return 'cmv-retry';
      default:
        return 'cmv-download';
    }
  }
}
