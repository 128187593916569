import {
  CdkDragDrop,
  moveItemInArray,
  CdkDropList,
  CdkDrag,
  CdkDragHandle,
} from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AnnotationType } from '../../../api/models';
import {
  EditTagOffsetModalComponent,
  TAG_OFFSET_TYPES,
} from '../../shared/components/modal/edit-tag-offset-modal/edit-tag-offset-modal.component';
import {
  INPUT_TYPE,
  RemoveModalComponent,
} from '../../shared/components/modal/remove-modal/remove-modal.component';
import { AppState } from '../../shared/models/app.state';
import {
  deleteAnnotationTypeRequestAction,
  getAnnotationTypesRequestAction,
  patchAnnotationTypeOrderRequestAction,
  patchAnnotationTypeRequestAction,
  postAnnotationTypeRequestAction,
} from '../../shared/store/actions/annotation-types.actions';
import {
  $annotationTypesEntities,
  $annotationTypesLoading,
} from '../../shared/store/selectors/annotation-types.selectors';
import { PageLayoutComponent } from '../../shared/components/page-layout/page-layout.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatRipple } from '@angular/material/core';
import { MatIconButton } from '@angular/material/button';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MenuItemComponent } from '../../shared/components/menu-item/menu-item.component';

const DEFAULT_PREROLL = 10;
const DEFAULT_DURATION = 20;

@Component({
  selector: 'cmv-tag-panel-view',
  templateUrl: './tag-panel-view.component.html',
  styleUrls: ['./tag-panel-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PageLayoutComponent,
    NgIf,
    MatIcon,
    CdkDropList,
    NgFor,
    CdkDrag,
    CdkDragHandle,
    MatRipple,
    MatIconButton,
    MatMenuTrigger,
    MatMenu,
    AsyncPipe,
    TranslateModule,
    MenuItemComponent,
  ],
})
export class TagPanelViewComponent {
  annotationTypes$ = this.store.pipe(select($annotationTypesEntities));
  loadingResources$ = this.store.pipe(select($annotationTypesLoading));

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog,
  ) {
    this.store.dispatch(getAnnotationTypesRequestAction());
  }

  createNewTag(): void {
    const dialogInstance = this.dialog.open(EditTagOffsetModalComponent, {
      autoFocus: false,
      data: {
        type: TAG_OFFSET_TYPES.CREATE,
        name: '',
        preroll: DEFAULT_PREROLL,
        duration: DEFAULT_DURATION,
      },
    });

    if (dialogInstance != null) {
      dialogInstance
        .afterClosed()
        .pipe(take(1))
        .subscribe(value => {
          if (value) {
            this.store.dispatch(
              postAnnotationTypeRequestAction({ request: value }),
            );
          }
        });
    }
  }

  openModal(type: string, tag: AnnotationType): void {
    let dialog;
    let handleFunction;
    switch (type) {
      case 'EditOffset':
        dialog = this.dialog.open(EditTagOffsetModalComponent, {
          autoFocus: false,
          data: {
            type: TAG_OFFSET_TYPES.EDIT,
            name: tag.name,
            preroll: tag.preroll,
            duration: tag.duration,
          },
        });
        handleFunction = (value: {
          name: string;
          preroll: number;
          duration: number;
        }) => {
          if (
            value &&
            (value.name !== tag.name ||
              value.duration !== tag.duration ||
              value.preroll !== tag.preroll)
          ) {
            this.store.dispatch(
              patchAnnotationTypeRequestAction({
                request: {
                  id: tag.id,
                  color: tag.color || '',
                  name: value.name,
                  preroll: value.preroll,
                  duration: value.duration,
                  order: tag.order,
                },
              }),
            );
          }
        };
        break;

      case 'RemoveTag':
        dialog = this.dialog.open(RemoveModalComponent, {
          data: {
            title: tag.name,
            type: INPUT_TYPE.TAG,
          },
        });
        handleFunction = (value: boolean) => {
          if (value) {
            this.store.dispatch(
              deleteAnnotationTypeRequestAction({ id: tag.id }),
            );
          }
        };
        break;

      default:
        return;
    }
    if (dialog != null) {
      dialog.afterClosed().pipe(take(1)).subscribe(handleFunction);
    }
  }

  drop(event: CdkDragDrop<AnnotationType[]>): void {
    this.store
      .pipe(select($annotationTypesEntities), take(1))
      .subscribe(entities => {
        if (entities == null || event.previousIndex === event.currentIndex) {
          return;
        }
        this.store.dispatch(
          patchAnnotationTypeOrderRequestAction({
            id: event.item.element.nativeElement.id,
            newOrder: event.currentIndex + 1,
            oldOrder: event.previousIndex + 1,
          }),
        );
        moveItemInArray(entities, event.previousIndex, event.currentIndex);
      });
  }
}
