import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { DefaultTermsOfUseComponent } from '../../../shared/components/default-terms-of-use/default-terms-of-use.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-terms-of-use-view',
  templateUrl: './terms-of-use-view.component.html',
  styleUrls: ['./terms-of-use-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [HeaderComponent, DefaultTermsOfUseComponent, TranslateModule],
})
export class TermsOfUseViewComponent {
  constructor() {}
}
