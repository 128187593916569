import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RoutePath } from '../../../app.constants';
import { AppState } from '../../models/app.state';
import { select, Store } from '@ngrx/store';
import { logoutAction } from '../../store/actions/auth.actions';
import { MatDialog } from '@angular/material/dialog';
import { LogoutModalComponent } from '../modal/logout-modal/logout-modal.component';
import { map, take } from 'rxjs/operators';
import { $liveRecordingsCount } from '../../store/selectors/recording-list.selectors';
import {
  $hasAnyRecorder,
  $recordersLoaded,
} from '../../store/selectors/recorders.selectors';
import {
  installPwaAction,
  toggleMenuCollapse,
} from '../../store/actions/ui-flags.actions';
import {
  $isPWA,
  $moreSubmenuOpen,
  $isMenuCollapsed,
} from '../../store/selectors/ui-flags.selectors';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';
import { NavigationItemComponent } from '../navigation-item/navigation-item.component';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    NgClass,
    NavigationItemComponent,
    NgIf,
    MatIcon,
    AsyncPipe,
    TranslateModule,
  ],
})
export class SidebarComponent {
  recordersLoaded$ = this.store.pipe(select($recordersLoaded));
  hasAnyRecorder$ = this.store.pipe(select($hasAnyRecorder));
  liveRecordingsCount$ = this.store.pipe(select($liveRecordingsCount));
  isMenuCollapsed$ = this.store.pipe(select($isMenuCollapsed));

  readonly showInstallPwa$ = this.store.pipe(
    select($isPWA),
    map(pwa => !pwa),
  );

  moreSubmenuOpen$ = this.store.pipe(select($moreSubmenuOpen));

  routePath = RoutePath;
  version: string = require('../../../../../package.json').version;

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog,
  ) {}

  installPWA(): void {
    this.store.dispatch(installPwaAction());
  }

  toggleCollapseMenu(): void {
    this.store.dispatch(toggleMenuCollapse());
  }

  onLogOut(): void {
    const dialog = this.dialog.open(LogoutModalComponent);
    const handleFunction = (value: boolean) => {
      if (value) {
        this.store.dispatch(logoutAction());
      }
    };
    if (dialog != null) {
      dialog.afterClosed().pipe(take(1)).subscribe(handleFunction);
    }
  }

  getCollapseIcon(isMenuCollapsed: boolean): string {
    return isMenuCollapsed ? 'cmv-arrow-right' : 'cmv-arrow-left';
  }
}
