@if (inVideoPlayer) {
  <div
    *ngIf="isTeamTaggingChangeEnabled$ | async"
    class="select-container"
    (click)="$event.stopPropagation()"
  >
    <div
      class="select-button"
      [ngClass]="{ shown: taggingTeamShown }"
      (click)="toggleOptions()"
    >
      <div
        class="select-options-container"
        [ngClass]="{ shown: taggingTeamShown }"
      >
        <div
          class="select-option"
          (click)="selectTaggingTeam(taggingTeam.BOTH)"
        >
          {{ 'components.taggingPanel.both' | translate }}
          <mat-icon
            *ngIf="(currentTaggingTeam$ | async) === taggingTeam.BOTH"
            class="select-option--checkmark"
            svgIcon="cmv-checkmark"
          ></mat-icon>
        </div>
        <div
          class="select-option"
          (click)="selectTaggingTeam(taggingTeam.HOME)"
        >
          {{ 'components.taggingPanel.home' | translate }}
          <mat-icon
            *ngIf="(currentTaggingTeam$ | async) === taggingTeam.HOME"
            class="select-option--checkmark"
            svgIcon="cmv-checkmark"
          ></mat-icon>
        </div>
        <div
          class="select-option"
          (click)="selectTaggingTeam(taggingTeam.AWAY)"
        >
          {{ 'components.taggingPanel.away' | translate }}
          <mat-icon
            *ngIf="(currentTaggingTeam$ | async) === taggingTeam.AWAY"
            class="select-option--checkmark"
            svgIcon="cmv-checkmark"
          ></mat-icon>
        </div>
      </div>
      <div class="cmv-h4">
        {{ taggingTeamString(currentTaggingTeam$ | async) }}
      </div>
    </div>
  </div>
} @else {
  <div *ngIf="isTeamTaggingChangeEnabled$ | async" class="header-tagging--more">
    <button
      mat-icon-button
      class="cmv-more-button"
      [matMenuTriggerFor]="selectMenu"
      (click)="$event.stopPropagation()"
      [matTooltip]="'tooltips.taggingTeam' | translate"
    >
      <div class="cmv-h4">
        {{ taggingTeamString(currentTaggingTeam$ | async) }}
      </div>
    </button>
  </div>
  <mat-menu class="cmv-menu-container" #selectMenu="matMenu">
    <div class="cmv-menu-tag-select-container">
      <button
        (click)="selectTaggingTeam(taggingTeam.BOTH)"
        class="cmv-menu-item"
        mat-menu-item
      >
        <span class="cmv-h4">
          {{ 'components.taggingPanel.both' | translate }}
        </span>
        <span class="source-checkmark-icon-wrapper">
          <mat-icon
            class="modal-icon cmv-gray-icon source-check-icon"
            *ngIf="(currentTaggingTeam$ | async) === taggingTeam.BOTH"
            svgIcon="cmv-checkmark"
          ></mat-icon>
        </span>
      </button>
      <button
        (click)="selectTaggingTeam(taggingTeam.HOME)"
        class="cmv-menu-item"
        mat-menu-item
      >
        <span class="cmv-h4">
          {{ 'components.taggingPanel.home' | translate }}
        </span>
        <span class="source-checkmark-icon-wrapper">
          <mat-icon
            class="modal-icon cmv-gray-icon source-check-icon"
            *ngIf="(currentTaggingTeam$ | async) === taggingTeam.HOME"
            svgIcon="cmv-checkmark"
          ></mat-icon>
        </span>
      </button>
      <button
        (click)="selectTaggingTeam(taggingTeam.AWAY)"
        class="cmv-menu-item"
        mat-menu-item
      >
        <span class="cmv-h4">
          {{ 'components.taggingPanel.away' | translate }}
        </span>
        <span class="source-checkmark-icon-wrapper">
          <mat-icon
            class="modal-icon cmv-gray-icon source-check-icon"
            *ngIf="(currentTaggingTeam$ | async) === taggingTeam.AWAY"
            svgIcon="cmv-checkmark"
          ></mat-icon>
        </span>
      </button>
    </div>
  </mat-menu>
}
