import {
  Directive,
  ElementRef,
  HostListener,
  OnChanges,
  OnInit,
  Renderer2,
} from '@angular/core';

const CHAR_WIDTH = 9;

@Directive({
  selector: '[cmvFluidInput]',
  standalone: true,
})
export class FluidInputDirective implements OnInit, OnChanges {
  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  @HostListener('keyup') onKeyup(): void {
    this.setWidth();
  }

  @HostListener('change') onChange(): void {
    this.setWidth();
  }

  ngOnInit(): void {
    this.renderer.setStyle(
      this.el.nativeElement,
      'margin-right',
      `-${CHAR_WIDTH}px`,
    );
    this.setWidth();
  }

  ngOnChanges(): void {
    this.setWidth();
  }

  get width(): number {
    return this.el.nativeElement.value.length * CHAR_WIDTH + CHAR_WIDTH;
  }

  setWidth(): void {
    this.renderer.setStyle(this.el.nativeElement, 'width', `${this.width}px`);
  }
}
