import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { QueryParamNames, RoutePath } from '../../../app.constants';
import { AppState } from '../../../shared/models/app.state';
import {
  $recordingAsFormInput,
  $recordingsLoaded,
} from '../../../shared/store/selectors/recording-list.selectors';
import { $currentRecordingId } from '../../../shared/store/selectors/route.selectors';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { ManualSchedulingFormComponent } from '../../../shared/components/manual-scheduling-form/manual-scheduling-form.component';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-edit-recording-view',
  templateUrl: './edit-recording-view.component.html',
  styleUrls: ['./edit-recording-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PageLayoutComponent,
    NgIf,
    ManualSchedulingFormComponent,
    MatIcon,
    AsyncPipe,
    TranslateModule,
  ],
})
export class EditRecordingViewComponent {
  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
    this.route.queryParams.pipe(take(1)).subscribe(queryParams => {
      if (
        !Object.keys(queryParams).length ||
        !queryParams[QueryParamNames.RecordingId]
      ) {
        this.createRecording();
      }
    });
  }

  readonly currentRecordingId$ = this.store.pipe(select($currentRecordingId));
  readonly recordingsLoaded$ = this.store.pipe(select($recordingsLoaded));
  readonly recordingAsFormInput$ = (id: string) =>
    this.store.pipe(select($recordingAsFormInput(id)));

  createRecording(): void {
    this.router.navigate([
      RoutePath.Platform,
      RoutePath.Recordings,
      RoutePath.Create,
    ]);
  }
}
