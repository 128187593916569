import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DefaultTermsOfUseComponent } from '../../shared/components/default-terms-of-use/default-terms-of-use.component';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'cmv-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, DefaultTermsOfUseComponent, TranslateModule],
})
export class TermsComponent {
  constructor() {
    window.scrollTo(0, 0);
  }
}
