import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Platform } from '@angular/cdk/platform';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-pwa-install-guide-modal',
  templateUrl: './pwa-install-guide-modal.component.html',
  styleUrls: ['./pwa-install-guide-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon, NgIf, TranslateModule],
})
export class PwaInstallGuideModalComponent implements AfterViewInit {
  @ViewChild('elementToFocus')
  elementToFocus: ElementRef;

  constructor(
    private readonly dialogRef: MatDialogRef<PwaInstallGuideModalComponent>,
    readonly platform: Platform,
  ) {}

  readonly isTouchScreenDevice = (): boolean =>
    Boolean(this.platform.ANDROID || this.isIPhone() || this.isIPad());

  readonly isIPhone = (): boolean => Boolean(this.platform.IOS);

  readonly isIPad = (): boolean =>
    Boolean(
      (navigator.userAgent && navigator.userAgent.indexOf('iPad') >= 0) ||
        (this.isMac() &&
          navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 2),
    );

  readonly isMac = (): boolean =>
    Boolean(
      navigator.userAgent && navigator.userAgent.indexOf('Intel Mac OS X') >= 0,
    );

  readonly isChrome = (): boolean =>
    Boolean(
      navigator.userAgent &&
        (navigator.userAgent.indexOf('Chrome') >= 0 ||
          navigator.userAgent.indexOf('CriOS') >= 0),
    );

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementToFocus.nativeElement.focus();
    }, 0);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
