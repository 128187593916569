import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatMenuItem } from '@angular/material/menu';

@Component({
  selector: 'cmv-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatMenuItem, MatIcon],
})
export class MenuItemComponent {
  @Input() label: string = '';
  @Input() icon: string;
  @Input() disabled: boolean = false;

  @Output() action = new EventEmitter<void>();

  onClickHandler(): void {
    this.action.emit();
  }
}
