import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
  standalone: true,
})
export class DurationPipe implements PipeTransform {
  constructor() {}
  transform(value: number): string {
    let seconds = Math.floor(value % 60);
    let timeString = `${seconds}s`;
    value = Math.floor(value / 60);
    if (value > 0) {
      let minutes = value % 60;
      timeString = `${minutes}m ${timeString}`;
      value = Math.floor(value / 60);
      if (value > 0) {
        timeString = `${value}h ${timeString}`;
      }
    }

    return timeString;
  }
}
