import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { DefaultTermsOfUseComponent } from '../../default-terms-of-use/default-terms-of-use.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-first-time-login-modal',
  templateUrl: './first-time-login-modal.component.html',
  styleUrls: ['./first-time-login-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon, DefaultTermsOfUseComponent, TranslateModule],
})
export class FirstTimeLoginModalComponent implements AfterViewInit {
  @ViewChild('elementToFocus')
  elementToFocus: ElementRef;

  constructor(
    private readonly dialogRef: MatDialogRef<FirstTimeLoginModalComponent>,
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementToFocus.nativeElement.focus();
    }, 0);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  closeModal(remove: boolean): void {
    this.dialogRef.close(remove);
  }
}
