import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { RecordingTeam } from 'src/api/models';
import { getFullTeamName } from '../../../../app.utils';

@Component({
  selector: 'cmv-match-widget-team',
  templateUrl: './match-widget-team.component.html',
  styleUrls: ['./match-widget-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class MatchWidgetTeamComponent {
  @Input() team: RecordingTeam;
  @Input() reverse: boolean = false;

  constructor(private readonly sanitizer: DomSanitizer) {}

  getTeamLogo(url: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }

  getFullTeamName(team: RecordingTeam): string {
    return getFullTeamName(team);
  }
}
