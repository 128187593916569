import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

const CANVAS_WIDTH = 16;
const CANVAS_HEIGHT = 360;
const TRANSITION = 'transform 200ms';
const KNOB_SIZE = 32;
const BORDER_SIZE = 2;
const SATURATION = 85;
const KNOB_LEFT_DIVISOR = 4;

@Component({
  selector: 'cmv-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ColorPickerComponent implements AfterViewInit {
  @ViewChild('myCanvas', { static: true }) myCanvas: ElementRef;
  @ViewChild('knob', { static: true }) myKnob: ElementRef;

  bottom = -KNOB_SIZE / 2;
  bgColor = `hsl(0, ${SATURATION}%, 50%)`;
  transformY = 'translateY(0px)';
  transition = '';

  storedHeight = CANVAS_HEIGHT;
  storedKnobSize = KNOB_SIZE;
  storedWidth = CANVAS_WIDTH;
  rendered = false;
  colorIndex = CANVAS_HEIGHT - 1;

  @Output() colorChanges = new EventEmitter<string>();

  @Input() set canvasWidth(width: number) {
    this.storedWidth = width;
    this.redrawCanvas();
  }

  get canvasWidth(): number {
    return this.storedWidth;
  }

  @Input() set canvasHeight(height: number) {
    const newKnobPosition = this.recalculateKnobPosition(
      height,
      this.colorIndex,
      this.canvasBorderlessHeight,
    );
    this.storedHeight = height;
    this.redrawCanvas();
    this.redrawKnob(newKnobPosition.newIndex, newKnobPosition.move);
  }

  get canvasHeight(): number {
    return this.storedHeight;
  }

  @Input() set knobSize(knobSize: number) {
    this.storedKnobSize = knobSize;
    this.bottom = -knobSize / 2;
  }

  get knobSize(): number {
    return this.storedKnobSize;
  }

  get knobLeft(): number {
    return -this.knobSize / KNOB_LEFT_DIVISOR;
  }

  private get canvasElement(): HTMLCanvasElement {
    return this.myCanvas && this.myCanvas.nativeElement;
  }

  private get canvasContext(): CanvasRenderingContext2D | null {
    return this.canvasElement && this.canvasElement.getContext('2d');
  }

  private get pickerBottom(): number {
    return this.canvasElement.getBoundingClientRect().bottom;
  }

  private get canvasBorderlessHeight(): number {
    return this.canvasHeight - BORDER_SIZE * 2;
  }

  ngAfterViewInit(): void {
    this.drawPickerCanvas();
  }

  redrawCanvas(): void {
    if (this.rendered) {
      setTimeout(() => this.drawPickerCanvas(), 0);
    }
  }

  redrawKnob(index: number, move: number): void {
    if (this.rendered) {
      this.colorIndex = index;
      this.transition = '';
      this.transformY = `translateY(${move * -1}px)`;
    }
  }

  recalculateKnobPosition(
    newHeight: number,
    index: number,
    height: number,
  ): { newIndex: number; move: number } {
    const newIndex = Math.round(
      ((newHeight - BORDER_SIZE * 2) * index) / height,
    );
    const move = Math.max(newHeight - BORDER_SIZE * 2 - newIndex, 0);
    return {
      newIndex,
      move,
    };
  }

  drawPickerCanvas(): void {
    const coefficient = CANVAS_HEIGHT / this.canvasHeight;
    const values = Array(this.canvasHeight)
      .fill(null)
      .map((_, i) => i * coefficient);

    this.canvasContext!.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    values.map((v, i) => {
      this.canvasContext!.fillStyle = `hsl(${v}, ${SATURATION}%, 50%)`;
      this.canvasContext!.fillRect(0, i, this.canvasWidth, 1);
    });
    if (!this.rendered) {
      this.colorChanges.emit(this.getColor(this.canvasHeight - 1));
    }
    this.rendered = true;
  }

  swipeHandle(event: HammerInput): void {
    this.transition = '';
    this.handleKnobMovement(event.srcEvent as PointerEvent);
  }

  handleKnobMovement(event: PointerEvent | MouseEvent): void {
    const offset = this.pickerBottom - event.clientY;
    const move =
      offset > this.canvasBorderlessHeight
        ? this.canvasBorderlessHeight
        : offset < 0
          ? 0
          : offset;
    this.transformY = `translateY(${move * -1}px)`;
    this.colorIndex = Math.min(
      this.canvasBorderlessHeight - move,
      this.canvasBorderlessHeight - 1,
    );
    this.bgColor = this.getColor(this.colorIndex);
    this.colorChanges.emit(this.bgColor);
  }

  getColor(index: number): string {
    const [r, g, b] = Array.from(
      this.canvasContext!.getImageData(0, index, 1, 1).data,
    );
    return `rgb(${r}, ${g}, ${b})`;
  }

  clickHandle(event: MouseEvent): void {
    this.transition = TRANSITION;
    this.handleKnobMovement(event);
  }
}
