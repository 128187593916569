<div>
  <cmv-search></cmv-search>
</div>
<div class="filter-result-container">
  <div
    class="filter-results-wrapper"
    *ngIf="filterResults$ | async as filterResults"
  >
    <div class="results">
      <div class="empty-results" *ngIf="noResults$ | async">
        <div class="cmv-h1">
          {{ 'components.search.noResults' | translate }}
        </div>
      </div>
      <ng-container
        *ngFor="let match of filterResults; trackBy: resultsTrackBy"
      >
        @if (match.itemType === FilterSearchModelType.RECORDING) {
          <cmv-match-widget
            [navigation]="true"
            [match]="match"
          ></cmv-match-widget>
        } @else {
          <cmv-video-widget
            [widgetData]="match"
            (click)="redirectToAnnotation(match.id, match.recordingId)"
            (trimVideo)="redirectToTrimming($event.id, $event.recordingId)"
            (editAnnotation)="editAnnotation($event)"
            (deleteTag)="deleteTag($event)"
            [hideDownload]="true"
          ></cmv-video-widget>
        }
      </ng-container>
    </div>
  </div>
</div>
