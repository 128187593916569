<a
  [routerLink]="routerLink"
  [queryParams]="routerQueryParameters"
  [class.canNavigate]="navigation === true"
  class="playlist-title-container"
  *ngIf="playlist"
  [ngClass]="{
    'empty-playlist': playlistLength === 0,
  }"
>
  <div class="cmv-h2 playlist-name">{{ playlist.name }}</div>
  <div class="right-side">
    <div class="cmv-h2 playlist-count">
      {{ playlistLength }}
      {{
        playlistLength === 1
          ? ('components.playlistDetail.video' | translate)
          : ('components.playlistDetail.videos' | translate)
      }}
    </div>
    <button
      (click)="menuButtonClickHandler($event)"
      class="cmv-more-button"
      mat-icon-button
      [matMenuTriggerFor]="detailMenu"
    >
      <img src="assets/icons/more_icon_ver.svg" />
    </button>

    <mat-menu class="cmv-menu-container" #detailMenu="matMenu">
      <div (keydown)="$event.stopPropagation()">
        <cmv-menu-item
          (action)="openModal('EditModal')"
          [label]="'basic.editPlaylist' | translate"
          icon="cmv-edit"
        />
        <cmv-menu-item
          *ngIf="canDownload"
          (action)="downloadHandler(playlist.id)"
          [label]="downloadButtonLabel | translate"
          [icon]="downloadButtonIcon"
        />
        <cmv-menu-item
          (action)="openModal('RemoveModal')"
          [label]="'basic.removePlaylist' | translate"
          icon="cmv-delete"
        />
      </div>
    </mat-menu>
  </div>
</a>
<cmv-loading-bar
  *ngIf="
    playlist?.status === PlaylistStatus.RENDERING ||
    playlist?.status === PlaylistStatus.ZIPPING
  "
></cmv-loading-bar>
