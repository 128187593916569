import {
  Component,
  ChangeDetectionStrategy,
  HostListener,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppNewsModalmodel } from 'src/app/shared/models/app-news-modal.model';
import { NgFor } from '@angular/common';
import { VersionInfoComponent } from '../../version-info/version-info.component';
import { LoadingStateComponent } from '../../loading-state/loading-state.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-not-authorized-modal',
  templateUrl: './app-news-modal.component.html',
  styleUrls: ['./app-news-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgFor,
    VersionInfoComponent,
    LoadingStateComponent,
    TranslateModule,
  ],
})
export class AppNewsModalComponent implements AfterViewInit {
  @ViewChild('elementToFocus')
  elementToFocus: ElementRef;
  versions: Array<{ version: string; loaded: boolean }>;
  loaded = false;

  constructor(
    private readonly dialogRef: MatDialogRef<AppNewsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: AppNewsModalmodel,
  ) {
    this.versions = data.versions.map(v => ({
      version: v,
      loaded: false,
    }));
    this.loaded = false;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementToFocus.nativeElement.focus();
    }, 0);
  }

  onLoad(version: string) {
    let index = this.versions.findIndex(v => v.version === version);
    this.versions[index].loaded = true;
    this.loaded = this.versions.filter(v => v.loaded === false).length === 0;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  closeModal(output: boolean): void {
    this.dialogRef.close(output);
  }
}
