import { Directive, OnDestroy } from '@angular/core';
import { MonoTypeOperatorFunction, Subject, takeUntil } from 'rxjs';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractComponent implements OnDestroy {
  protected readonly destroyedSubject = new Subject<boolean>();

  ngOnDestroy(): void {
    this.destroyedSubject.next(true);
    this.destroyedSubject.complete();
  }

  untilDestroyed<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil(this.destroyedSubject);
  }
}
