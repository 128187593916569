import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { remuxRecordingVideoRequestAction } from 'src/app/shared/store/actions/recording.actions';
import { AppState } from '../../../models/app.state';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-remux-video-modal',
  templateUrl: './remux-modal.component.html',
  styleUrls: ['./remux-video-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon, TranslateModule],
})
export class RemuxVideoModalComponent implements AfterViewInit {
  videoId: string;

  @ViewChild('elementToFocus')
  elementToFocus: ElementRef;

  constructor(
    private readonly dialogRef: MatDialogRef<RemuxVideoModalComponent>,
    private readonly store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA)
    data: { videoId: string },
  ) {
    this.videoId = data.videoId;
  }

  remuxVideo(): void {
    this.closeModal(true);
    this.store.dispatch(
      remuxRecordingVideoRequestAction({
        videoId: this.videoId,
      }),
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementToFocus.nativeElement.focus();
    }, 0);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  closeModal(output: boolean): void {
    this.dialogRef.close(output);
  }
}
