@if (match) {
  <div class="video-header-panel">
    <div>
      @switch (match.type) {
        @case (RecordingType.MATCH) {
          <div class="teams-container">
            <cmv-match-widget-team
              [team]="match.teamHome"
            ></cmv-match-widget-team>
            <div>&nbsp;:&nbsp;</div>
            <cmv-match-widget-team
              [reverse]="true"
              [team]="match.teamAway"
            ></cmv-match-widget-team>
          </div>
        }
        @case (RecordingType.TRAINING) {
          <cmv-match-widget-team
            [team]="match.teamHome"
          ></cmv-match-widget-team>
        }
        @case (RecordingType.OTHER) {
          <div class="cmv-h4">
            {{ match.name }}
          </div>
        }
      }
    </div>
  </div>
}
