import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-how-it-works-view',
  templateUrl: './how-it-works-view.component.html',
  styleUrls: ['./how-it-works-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [HeaderComponent, TranslateModule],
})
export class HowItWorksViewComponent {
  constructor() {}
}
