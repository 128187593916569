import { enableProdMode, importProvidersFrom } from '@angular/core';

import { CustomHammerConfig, HttpLoaderFactory } from './app/app.module';
import { environment } from './environments/environment';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
  HttpClient,
} from '@angular/common/http';
import { TokenInterceptor } from './app/shared/interceptors/token.interceptor';
import {
  HAMMER_GESTURE_CONFIG,
  BrowserModule,
  HammerModule,
  bootstrapApplication,
} from '@angular/platform-browser';
import { AppRoutingModule } from './app/app-routing.module';
import {
  provideNoopAnimations,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { APIClientModule } from './api';
import { StoreModule } from '@ngrx/store';
import { reducers } from './app/shared/store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { platformEffects } from './app/shared/store/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MarkdownModule } from 'ngx-markdown';
import { AppComponent } from './app/app.component';
import { MaterialModule } from './app/shared/material/material.module';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      MaterialModule,
      BrowserModule,
      AppRoutingModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      APIClientModule.forRoot({ domain: environment.apiUrl }),
      StoreModule.forRoot(reducers),
      EffectsModule.forRoot(platformEffects),
      StoreDevtoolsModule.instrument({
        maxAge: 25, // Retains last 25 states
        connectInZone: true,
      }),
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        registrationStrategy: 'registerImmediately',
      }),
      HammerModule,
      MarkdownModule.forRoot({ loader: HttpClient }),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CustomHammerConfig,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideNoopAnimations(),
    provideAnimations(),
  ],
}).catch(err => console.error(err));
