<button
  [disabled]="disabled"
  (click)="onClickHandler()"
  class="cmv-menu-item"
  mat-menu-item
>
  <span class="cmv-h4">
    {{ label }}
  </span>
  <span class="menu-icon--wrapper">
    <mat-icon class="modal-icon cmv-gray-icon" [svgIcon]="icon" />
  </span>
</button>
