<div
  class="platform"
  [ngClass]="{ 'fake-fullscreen-active': (fakeFullscreenActive$ | async) }"
>
  @if ((fakeFullscreenActive$ | async) === false) {
    <div
      class="sidebar-wrapper"
      [class.menuCollapsed]="isMenuCollapsed$ | async"
    >
      <cmv-pwa-install-button></cmv-pwa-install-button>
      <cmv-sidebar></cmv-sidebar>
    </div>
  }
  <div class="platform-content-wrapper">
    <div class="platform-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<cmv-error></cmv-error>
@if ((fakeFullscreenActive$ | async) === false) {
  <cmv-snackbar></cmv-snackbar>
}
