import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { MatRipple } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';

enum TAG_FORM_CONTROLS {
  NAME = 'name',
  PREROLL = 'preroll',
  DURATION = 'duration',
}

export enum TAG_OFFSET_TYPES {
  CREATE = 'tagCreate',
  EDIT = 'tagEdit',
}

const MIN_PREROLL = 0;
const MIN_DURATION = 1;
const MAX_PREROLL = 180;
const MAX_DURATION = 360;

@Component({
  selector: 'cmv-edit-tag-offset-modal',
  templateUrl: './edit-tag-offset-modal.component.html',
  styleUrls: ['./edit-tag-offset-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIcon,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    NgIf,
    MatError,
    MatRipple,
    TranslateModule,
  ],
})
export class EditTagOffsetModalComponent implements OnDestroy, AfterViewInit {
  tagForm: FormGroup;
  type: TAG_OFFSET_TYPES;
  tagFormControls = TAG_FORM_CONTROLS;

  @ViewChild('elementToFocus')
  elementToFocus: ElementRef;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly dialogRef: MatDialogRef<EditTagOffsetModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      type: TAG_OFFSET_TYPES;
      name: string;
      preroll: number;
      duration: number;
    },
    private readonly fb: FormBuilder,
  ) {
    this.type = data.type;
    this.tagForm = this.fb.group({
      [TAG_FORM_CONTROLS.NAME]: [
        data.name,
        {
          validators: [Validators.required],
          updateOn: 'change',
        },
      ],
      [TAG_FORM_CONTROLS.PREROLL]: [
        data.preroll,
        {
          validators: [
            Validators.required,
            Validators.pattern('^[0-9]+$'),
            Validators.max(MAX_PREROLL),
            Validators.min(MIN_PREROLL),
          ],
          updateOn: 'change',
        },
      ],
      [TAG_FORM_CONTROLS.DURATION]: [
        data.duration,
        {
          validators: [
            Validators.required,
            Validators.pattern('^[0-9]+$'),
            Validators.max(MAX_DURATION),
            Validators.min(MIN_DURATION),
          ],
          updateOn: 'change',
        },
      ],
    });
  }

  closeModal(save: boolean): void {
    if (save) {
      this.dialogRef.close(this.tagForm.value);
    } else {
      this.dialogRef.close(false);
    }
  }

  get subPrerollDisabled(): boolean {
    const preroll = this.tagForm.get(TAG_FORM_CONTROLS.PREROLL);
    return preroll ? preroll.value <= MIN_PREROLL : true;
  }

  get addPrerollDisabled(): boolean {
    const preroll = this.tagForm.get(TAG_FORM_CONTROLS.PREROLL);
    return preroll ? preroll.value >= MAX_PREROLL : true;
  }

  get subDurationDisabled(): boolean {
    const duration = this.tagForm.get(TAG_FORM_CONTROLS.DURATION);
    return duration ? duration.value <= MIN_DURATION : true;
  }

  get addDurationDisabled(): boolean {
    const duration = this.tagForm.get(TAG_FORM_CONTROLS.DURATION);
    return duration ? duration.value >= MAX_DURATION : true;
  }

  get invalidName(): boolean {
    return this.tagForm.get(TAG_FORM_CONTROLS.NAME)!.errors != null;
  }

  get invalidPreroll(): boolean {
    return this.tagForm.get(TAG_FORM_CONTROLS.PREROLL)!.errors != null;
  }

  get prerollErrorMessage(): string {
    if (!this.invalidPreroll) {
      return 'somethingWrong';
    }
    const prerollErrors = this.tagForm.get(TAG_FORM_CONTROLS.PREROLL)!.errors;
    if (prerollErrors!['required']) {
      return 'required';
    } else if (prerollErrors!['min']) {
      return 'minValue';
    } else if (prerollErrors!['max']) {
      return 'maxValue';
    } else {
      return 'numbersOnly';
    }
  }

  get invalidDuration(): boolean {
    return this.tagForm.get(TAG_FORM_CONTROLS.DURATION)!.errors != null;
  }

  get durationErrorMessage(): string {
    if (!this.invalidDuration) {
      return 'somethingWrong';
    }
    const durationErrors = this.tagForm.get(TAG_FORM_CONTROLS.DURATION)!.errors;
    if (durationErrors!['required']) {
      return 'required';
    } else if (durationErrors!['min']) {
      return 'minValue';
    } else if (durationErrors!['max']) {
      return 'maxValue';
    } else {
      return 'numbersOnly';
    }
  }

  patchOffsetValue(property: TAG_FORM_CONTROLS, method: 'add' | 'sub'): void {
    const currentVal = this.tagForm.get(property)!.value || 0;
    this.tagForm.patchValue({
      [property]: method === 'add' ? currentVal + 1 : currentVal - 1,
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementToFocus.nativeElement.focus();
    }, 0);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
