<div
  class="page-layout"
  [class]="{ 'hide-header-on-mobile': hideHeaderOnMobile }"
>
  @if ((fakeFullscreenActive$ | async) === false) {
    <div class="header-wrapper">
      <cmv-header
        *ngIf="showHeader"
        [title]="title"
        [subtitle]="subtitle"
        [searchButtonEnabled]="searchButtonEnabled"
        [searchEnabled]="searchBarVisible$ | async"
        [actionButtonEnabled]="addButtonEnabled"
        (actionButtonClicked)="addButtonClicked.emit()"
      ></cmv-header>
    </div>
  }

  <div class="page-content">
    @if (loadingResources) {
      <cmv-loading-state [overlay]="true"></cmv-loading-state>
    } @else {
      <ng-content></ng-content>
    }
  </div>
</div>
