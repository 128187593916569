import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: { enable: false },
    rotate: { enable: false },
    pan: { direction: 30, threshold: 1 },
  };
}

export const HttpLoaderFactory = (http: HttpClient): TranslateHttpLoader =>
  new TranslateHttpLoader(http, 'assets/languages/', '.json');
