import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PageLayoutComponent } from '../page-layout/page-layout.component';

@Component({
  selector: 'cmv-video-page-layout',
  templateUrl: './video-page-layout.component.html',
  styleUrls: ['./video-page-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PageLayoutComponent],
})
export class VideoPageLayoutComponent {
  @Input() showHeader: boolean = true;
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() loadingResources: boolean = false;

  constructor() {}
}
