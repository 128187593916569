import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { secondsToHours } from 'src/app/app.utils';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-free-space-visualizer',
  templateUrl: './free-space-visualizer.component.html',
  styleUrls: ['./free-space-visualizer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTooltip, TranslateModule],
})
export class FreeSpaceVisualizerComponent {
  @Input() used: number;
  @Input() limit: number;

  get usedStoreInPercent() {
    return Math.min((this.used / this.limit) * 100, 100);
  }

  get usedHours() {
    return secondsToHours(this.used);
  }

  get limitHours() {
    return secondsToHours(this.limit);
  }

  get freeSpace() {
    return secondsToHours(this.limit - this.used);
  }
}
