import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { AnnotationList, RenderStatus } from 'src/api/models';
import { AppState } from '../../models/app.state';
import {
  ShareableEntityModel,
  ShareableEntityType,
} from '../../models/sharing.model';
import { getSharingTeamsRequestAction } from '../../store/actions/sharing.actions';
import { ShareModalComponent } from '../modal/share-modal/share-modal.component';
import { downloadAnnotationsListRequestAction } from '../../store/actions/annotations-lists.actions';
import {
  $currentRecordingHasVideo,
  $isMultiselectEnabled,
  $isSelectionEmpty,
  $selectionEmptyOrContainsSharedAnnotations,
} from '../../store/selectors/current-selections.selectors';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-created-tags-more',
  templateUrl: './created-tags-more.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    MatIcon,
    MatIconButton,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    AsyncPipe,
    TranslateModule,
  ],
})
export class CreatedTagsMoreComponent {
  @Input() shareableList: AnnotationList;
  @Input() annotationsEmpty: Boolean = true;
  @Input() isDownloadPrepared: Boolean = false;

  readonly currentRecordingHasVideo$ = this.store.pipe(
    select($currentRecordingHasVideo),
  );

  readonly isMultiselectEnabled$ = this.store.pipe(
    select($isMultiselectEnabled),
  );

  readonly isSelectionEmpty$ = this.store.pipe(select($isSelectionEmpty));
  readonly selectionEmptyOrContainsSharedAnnotations$ = this.store.pipe(
    select($selectionEmptyOrContainsSharedAnnotations),
  );

  constructor(
    public readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
  ) {}

  shareAnnotationList(annotationList: AnnotationList): void {
    const shareable: ShareableEntityModel = {
      annotationListId: annotationList.id,
      sharings: annotationList.sharings,
      type: ShareableEntityType.LIST,
    };
    this.store.dispatch(getSharingTeamsRequestAction({ shareable }));
    this.dialog.open(ShareModalComponent, {
      data: {
        entityType: ShareableEntityType.LIST,
      },
    });
  }

  downloadAnnotationList(annotationList: AnnotationList): void {
    if (annotationList.status === RenderStatus.DONE && annotationList.url) {
      window.open(annotationList.url, '_blank');
    } else if (annotationList.status !== RenderStatus.RENDERING) {
      this.store.dispatch(
        downloadAnnotationsListRequestAction({ id: annotationList.id }),
      );
    }
  }

  addToPlaylist(): void {
    console.log('Add to playlist');
  }

  shareAnnotations(): void {
    console.log('Share annotations');
  }

  downloadAnnotations(): void {
    console.log('Download annotations');
  }

  deleteAnnotations(): void {
    console.log('Delete annotations');
  }

  get downloadButtonLabel(): string {
    return this.shareableList?.status === RenderStatus.RENDERING
      ? 'basic.renderingList'
      : 'basic.downloadList';
  }

  get downloadButtonIcon(): string {
    switch (this.shareableList?.status) {
      case RenderStatus.RENDERING:
        return 'cmv-loading';
      case RenderStatus.FAILED:
        return 'cmv-retry';
      default:
        return 'cmv-download';
    }
  }
}
