{
  "name": "camvision",
  "version": "1.37.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "debug": "ng serve --configuration=staging ",
    "build": "ng build --configuration production && npm run edit-ngsw",
    "dev": "ng build --configuration dev && npm run edit-ngsw",
    "stage": "ng build --configuration=staging && npm run edit-ngsw",
    "lint": "ng lint",
    "lint-fix": "ng lint --fix --project camvision",
    "stylelint": "stylelint \"src/**/*.scss\"",
    "gen-api": "api-client-generator -s ../swagger-ui.yaml -Co src/api",
    "edit-ngsw": "replace \"onFetch\\(event\\) \\{\" \"onFetch(event) { return;\" dist/camvision/ngsw-worker.js",
    "format": "prettier ./src --write"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.3",
    "@angular/cdk": "^19.1.1",
    "@angular/common": "^19.1.3",
    "@angular/compiler": "^19.1.3",
    "@angular/core": "^19.1.3",
    "@angular/forms": "^19.1.3",
    "@angular/material": "^19.1.1",
    "@angular/material-moment-adapter": "^19.1.1",
    "@angular/platform-browser": "^19.1.3",
    "@angular/platform-browser-dynamic": "^19.1.3",
    "@angular/router": "^19.1.3",
    "@angular/service-worker": "^19.1.3",
    "@ngrx/effects": "^19.0.0",
    "@ngrx/entity": "^19.0.0",
    "@ngrx/operators": "^19.0.0",
    "@ngrx/store": "^19.0.0",
    "@ngrx/store-devtools": "^19.0.0",
    "@ngx-translate/core": "^16.0.4",
    "@ngx-translate/http-loader": "^16.0.1",
    "@types/debug": "^4.1.12",
    "@types/mdast": "^4.0.4",
    "@types/unist": "^3.0.3",
    "@videogular/ngx-videogular": "file:./libs/videogular-ngx-videogular-1.0.3.tgz",
    "amazon-cognito-auth-js": "^1.3.3",
    "core-js": "^3.40.0",
    "hammerjs": "^2.0.8",
    "hls.js": "^1.5.20",
    "moment": "^2.30.1",
    "ngx-markdown": "^19.0.0",
    "rxjs": "^7.8.1",
    "tslib": "^2.8.1"
  },
  "devDependencies": {
    "@angular-eslint/builder": "^19.0.2",
    "@angular-eslint/eslint-plugin": "^19.0.2",
    "@angular-eslint/eslint-plugin-template": "^19.0.2",
    "@angular-eslint/schematics": "19.0.2",
    "@angular-eslint/template-parser": "^19.0.2",
    "@angular/build": "^19.1.4",
    "@angular/cli": "^19.1.4",
    "@angular/compiler-cli": "^19.1.3",
    "@angular/language-service": "^19.1.3",
    "@types/amazon-cognito-auth-js": "^1.3.4",
    "@types/hammerjs": "^2.0.46",
    "@types/node": "^22.10.9",
    "@typescript-eslint/eslint-plugin": "8.20.0",
    "@typescript-eslint/parser": "^8.21.0",
    "api-client-generator": "^5.0.0-beta.6",
    "eslint": "^9.18.0",
    "eslint-plugin-import": "2.31.0",
    "eslint-plugin-jsdoc": "^50.6.2",
    "eslint-plugin-prefer-arrow": "latest",
    "prettier": "^3.4.2",
    "replace": "^1.2.2",
    "stylelint": "^16.13.1",
    "ts-node": "^10.9.2",
    "typescript": "~5.6.3"
  },
  "browserslist": [
    "> 0.5%",
    "last 2 versions",
    "safari >= 12",
    "iOS >= 12",
    "Firefox ESR",
    "not dead",
    "not op_mini all",
    "not kaios 2.5"
  ]
}
